import { useMutation, useQuery, useQueryClient } from "vue-query";
import useApi from "../useApi";
import { useUserStore } from "@/store/user";
import { computed, ref, inject } from "vue";
import { MyCoVehicleResponse } from "@/models/api/MyCoVehicleResponse";
import { MyCoVehicle } from "@/models/front/MyCoVehicle";

const useGetVehiclesQuery = () => {
    const { get, post, del, patch } = useApi();
    const queryClient = useQueryClient();
    
    const isLoading = ref(false);
    const userStore = useUserStore();
	const cacheKey = computed(() => ["vehicles", userStore.user.userName]);
	
    const getVehicles = () => {
    	
    	isLoading.value = true;
    	
        return get<MyCoVehicleResponse[]>("profile/vehicles", "").then((resp) => {
        	isLoading.value = false;
            return resp.data.map((v) => new MyCoVehicle(v));
        });
    };

    const addNewVehicleMutation = useMutation(
        (vehicle: MyCoVehicle) => {
            return post("profile/vehicles", vehicle);
        },
        {
            onMutate: async (vehicle) => {          	
        		
        		isLoading.value = true;
        		
        		await queryClient.cancelQueries(cacheKey);

          		const previousVehicles = queryClient.getQueryData(cacheKey);
				
				queryClient.setQueryData(cacheKey, (old?: MyCoVehicle[]) => (old ?? []).concat([vehicle]));
      			
          		return { previousVehicles };
	        },
	        onError: (err, vehicle, context) => {
      			queryClient.setQueryData(cacheKey, context?.previousVehicles ?? []);
    		},
    		onSettled: () => {
      			queryClient.invalidateQueries("vehicles");
      			isLoading.value = false;
    		},
        },
    );
    
    const updateVehicleMutation = useMutation(
        (vehicle: MyCoVehicle) => {
            return patch("profile/vehicles", vehicle);
        },
        {
            onMutate: async (updatedVehicle) => {          	
        		
        		isLoading.value = true;
        		
        		await queryClient.cancelQueries(cacheKey.value);

				const previousVehicles = queryClient.getQueryData<MyCoVehicle[]>(cacheKey.value);
				
          		const updatedVehicles = previousVehicles?.map(vehicle => 
  					vehicle.vehicleId === updatedVehicle.vehicleId ? 
  					new MyCoVehicle({ ...updatedVehicle }) : 
  					updatedVehicle.isDefault ? 
  					new MyCoVehicle({ ...vehicle, isDefault: false }) : 
  					new MyCoVehicle({...vehicle })
				) ?? [];
				
				queryClient.setQueryData(cacheKey.value, [...updatedVehicles]);
				
          		return { previousVehicles };
	        },
	        onError: (err, vehicle, context) => {
      			queryClient.setQueryData(cacheKey, context?.previousVehicles ?? []);
    		},
    		onSettled: () => {
      			queryClient.invalidateQueries(cacheKey.value);
      			isLoading.value = false;
    		},
        },
    );
    
    const removeVehicleMutation = useMutation(
    	(vehicle: MyCoVehicle) => {
    		return del(`profile/vehicles/${vehicle.vehicleId}`, "")
    	},
    	{
        	onMutate: async (vehicle) => {          	
        		
        		isLoading.value = true;
        		
        		await queryClient.cancelQueries(cacheKey);

          		const previousVehicles = queryClient.getQueryData(cacheKey);
				
				queryClient.setQueryData(cacheKey, (old?: MyCoVehicle[]) => (old ?? []).filter((oldVehicle) => oldVehicle.vehicleId !== vehicle.vehicleId));
      			
          		return { previousVehicles };
	        },
	        onError: (err, vehicle, context) => {
      			queryClient.setQueryData(cacheKey, context?.previousVehicles ?? []);
    		},
    		onSettled: () => {
      			queryClient.invalidateQueries(cacheKey);
      			isLoading.value = false;
    		},
        },
    );
    
    return {
        ...useQuery(cacheKey.value, getVehicles, {
            enabled: !!userStore.user.userName,
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5,
            keepPreviousData: true,
            retry: false,
        }),
        addNewVehicleMutation,
        removeVehicleMutation,
        updateVehicleMutation,
        isLoading,
    };
};

export default useGetVehiclesQuery;
