import { MyCoVehicleResponse } from "../api/MyCoVehicleResponse";

export class MyCoVehicle {
    vehicleId?: number;
    licenseNumber: string;
    length: number;
    width: number;
    delete: boolean;
    isDefault : boolean;

    constructor(resp?: MyCoVehicleResponse) {
    
        this.vehicleId = resp?.vehicleId;
        this.licenseNumber = resp?.licenseNumber ?? "";
        this.length = resp?.length ?? 0;
        this.width = resp?.width ?? 0;
        this.delete = false;
        this.isDefault = resp?.isDefault ?? false;
    }
}
